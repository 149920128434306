import "../../styles/styles.scss";

import React, { useEffect, useState } from "react";
import { animate, content, heroOverlay, navOpen } from "./layout.module.scss";

import Footer from "../footer/footer";
import Header from "../header/header";
import Seo from "../seo/seo";
import { globalHistory } from "@reach/router";
import logoShort from "../../assets/logos/logo-short.svg";

const Layout = ({ children, prevPath, seoOptions, showHero = true }) => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [animateHero, setAnimateHero] = useState(false);
  const [show, setShow] = useState(showHero);
  const [init] = useState(true);

  const onNavOpen = (value) => {
    setNavIsOpen(value);
  };

  const toggleAnimation = () => {
    setAnimateHero(true);
    setTimeout(() => setShow(false), 1000);
  };

  useEffect(() => {
    if (init) {
      setTimeout(() => toggleAnimation(), 100);
      globalHistory.listen(({ location }) => {
        if (location.state?.playLogoAnimation) {
          setShow(true);
          setAnimateHero(false);
          setTimeout(() => toggleAnimation(), 1000);
        }
      });
    }
  }, [init]);

  return (
    <>
      <Seo
        description={seoOptions?.description}
        title={seoOptions?.title}
        image={seoOptions?.image}
        pathname={seoOptions?.pathname}
      />
      <Header navOpenChange={onNavOpen} prevPath={prevPath} />
      {show && (
        <div
          className={`${heroOverlay} ${animateHero && animate}`}
          onClick={() => toggleAnimation()}
        >
          <img src={logoShort} alt="büro ganster architektur" />
        </div>
      )}
      <div className={`${content} ${navIsOpen && navOpen}`}>{children}</div>
      <Footer hide={navIsOpen} />
    </>
  );
};

export default Layout;
