import { activeLink, open, smallLinks } from "./navigation.module.scss";

import { GetInstagramLink } from "../../util/contactQuery";
import { Link } from "gatsby";
import React from "react";

const Navigation = ({ isOpen, close }) => {
  const onNavClick = () => {
    close();
  };
  const data = GetInstagramLink();
  const instagramUrl = data.strapiContact?.instagram ?? "";

  return (
    <nav
      className={`${isOpen ? `${open}` : ""}`}
      onClick={() => onNavClick()}
      onKeyDown={() => close()}
    >
      <Link to="/" activeClassName={activeLink}>
        aktuell
      </Link>
      <Link to="/projekte/" partiallyActive={true} activeClassName={activeLink}>
        projekte
      </Link>
      <Link to="/about/" partiallyActive={true} activeClassName={activeLink}>
        about
      </Link>
      <Link to="/team/" partiallyActive={true} activeClassName={activeLink}>
        team
      </Link>
      <Link to="/kontakt/" partiallyActive={true} activeClassName={activeLink}>
        kontakt
      </Link>
      <div className={smallLinks}>
        <Link
          to="/impressum/"
          partiallyActive={true}
          activeClassName={activeLink}
        >
          impressum & datenschutz
        </Link>
        <a href={instagramUrl} target="_blank">
          instagram
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
