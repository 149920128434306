import Layout from "./src/components/layout/layout";
import React from "react";

export const wrapPageElement = ({ element }) => {
  return (
    <Layout
      prevPath={element.type?.prevPath}
      seoOptions={{
        pathname: element.props?.path,
        ...element.type?.seoOptions,
      }}
      showHero={element.type?.showHero}
    >
      {element}
    </Layout>
  );
};
