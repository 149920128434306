import { Link } from "gatsby";
import React from "react";
import { previousPage } from "./previous-page-button.module.scss";

const PreviousPageButton = ({ to, text = "zurück", className, ...props }) => {
  return (
    <Link to={to} className={`${previousPage} ${className}`} {...props}>
      {text?.length > 0 ? <span>{text}</span> : null}
    </Link>
  );
};

export default PreviousPageButton;
