import { copyright, footer, hideFooter } from "./footer.module.scss";

import React from "react";
import logoShort from "../../assets/logos/logo-short.svg";

const Footer = ({ hide }) => {
  return (
    <footer className={`${footer} ${hide && hideFooter}`}>
      <div className={copyright}>
        © {new Date().getFullYear()}&nbsp;
        <br />
        büro ganster
        <br /> architektur
      </div>
      <img src={logoShort} alt="büro ganster architektur" />
    </footer>
  );
};

export default Footer;
