// extracted by mini-css-extract-plugin
export var dNone = "footer-module--d-none--g6MSj";
export var dBlock = "footer-module--d-block--fN38A";
export var dInline = "footer-module--d-inline---auv0";
export var dFlex = "footer-module--d-flex--QzCVx";
export var dMdNone = "footer-module--d-md-none--8NT2E";
export var dMdBlock = "footer-module--d-md-block--GEQco";
export var dMdFlex = "footer-module--d-md-flex--VrIrQ";
export var dLgFlex = "footer-module--d-lg-flex--Mzupb";
export var dLgNone = "footer-module--d-lg-none--w+224";
export var dLgBlock = "footer-module--d-lg-block--Jx3O6";
export var dLgInline = "footer-module--d-lg-inline--a7hgu";
export var footer = "footer-module--footer--lU7l1";
export var copyright = "footer-module--copyright--Bg9Rz";
export var hideFooter = "footer-module--hideFooter--4t4qb";