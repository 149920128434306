// extracted by mini-css-extract-plugin
export var dNone = "navigation-module--d-none--1Bmbx";
export var dBlock = "navigation-module--d-block--d4hcz";
export var dInline = "navigation-module--d-inline--TlkA2";
export var dFlex = "navigation-module--d-flex--ReSB0";
export var dMdNone = "navigation-module--d-md-none--uJJpd";
export var dMdBlock = "navigation-module--d-md-block--5EoJb";
export var dMdFlex = "navigation-module--d-md-flex--OXxml";
export var dLgFlex = "navigation-module--d-lg-flex--W7NeA";
export var dLgNone = "navigation-module--d-lg-none--B44F1";
export var dLgBlock = "navigation-module--d-lg-block--dPA55";
export var dLgInline = "navigation-module--d-lg-inline--4VKGz";
export var open = "navigation-module--open--eIiKU";
export var smallLinks = "navigation-module--smallLinks--r3Na-";
export var activeLink = "navigation-module--activeLink--hfVrw";