import React, { useState } from "react";
import {
  backActive,
  headerLogo,
  largeLogo,
  menuToggle,
  navOpen,
} from "./header.module.scss";

import { Link } from "gatsby";
import Navigation from "../navigation/navigation";
import PreviousPageButton from "../previous-page-button/previous-page-button";
import logoShort from "../../assets/logos/logo-short.svg";

/**
 * Type can be undefined, media or sport - changes logo
 */
const Header = ({ prevPath, navOpenChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    document.body.style.position = "";
    setIsOpen(false);
    if (navOpenChange) {
      navOpenChange(false);
    }
  };

  const open = () => {
    document.body.style.position = "fixed";
    setIsOpen(true);
    if (navOpenChange) {
      navOpenChange(true);
    }
  };

  const toggle = () => {
    isOpen ? close() : open();
  };

  return (
    <header>
      {!isOpen && prevPath ? (
        <PreviousPageButton
          className="d-flex d-lg-none"
          to={prevPath}
          text=""
        />
      ) : null}
      <Link
        onClick={close}
        to="/"
        state={{ playLogoAnimation: true }}
        className={`${headerLogo} ${prevPath && !isOpen ? backActive : ""}`}
      >
        <img
          src={logoShort}
          className={`${isOpen ? navOpen : ""} d-flex d-lg-none`}
          alt="büro ganster architektur"
        />
        <h1 className={`${isOpen ? "" : navOpen} ${largeLogo}`}>
          Büro Ganster
          <br />
          Architektur
        </h1>
      </Link>
      <Navigation isOpen={isOpen} close={close} />
      <button
        onClick={() => toggle()}
        className={`${menuToggle} ${isOpen ? navOpen : ""}`}
      >
        <span></span>
      </button>
    </header>
  );
};

export default Header;
