import { graphql, useStaticQuery } from "gatsby";

export const GetInstagramLink= () => {
  const contactData = useStaticQuery(graphql`
    query contact {
      strapiContact {
        instagram
      }
    }
  `);
  return contactData;
};