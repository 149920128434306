// extracted by mini-css-extract-plugin
export var dNone = "previous-page-button-module--d-none--o2FQV";
export var dBlock = "previous-page-button-module--d-block--l8rxf";
export var dInline = "previous-page-button-module--d-inline--bh85K";
export var dFlex = "previous-page-button-module--d-flex--t2S+X";
export var dMdNone = "previous-page-button-module--d-md-none--JwS2d";
export var dMdBlock = "previous-page-button-module--d-md-block--3NMly";
export var dMdFlex = "previous-page-button-module--d-md-flex--17jfA";
export var dLgFlex = "previous-page-button-module--d-lg-flex--uX6BE";
export var dLgNone = "previous-page-button-module--d-lg-none--p3dMn";
export var dLgBlock = "previous-page-button-module--d-lg-block--Gu8qP";
export var dLgInline = "previous-page-button-module--d-lg-inline--PWjt1";
export var previousPage = "previous-page-button-module--previousPage---fsCb";