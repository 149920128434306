module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"büro ganster architektur","short_name":"BGA","start_url":"/","background_color":"#edece9","theme_color":"#edece9","display":"minimal-ui","icon":"src/assets/logos/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bdec24edb1b922bf544d67afd79598f5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ganster-architektur.at"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
