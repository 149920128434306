import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

const Seo = ({ description, lang, meta, image: metaImage, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `,
  );
  const metaDescription = description || site.siteMetadata.description;

  if (metaImage) {
    metaImage = {
      src: metaImage.src,
      width: metaImage.width || 753,
      height: metaImage.height || 618,
    };
  } else {
    metaImage = {
      src: site.siteMetadata.image,
      width: 753,
      height: 618,
    };
  }
  const metaTitle = (title ? `${title} | ` : "") + site.siteMetadata.title;
  const scripts = [];
  if (process.env.NODE_ENV === "production") {
    scripts.push({
      type: "text/javascript",
      src: "https://cdn.usefathom.com/script.js",
      defer: true,
      "data-spa": "auto",
      "data-site": "ZWTYNGOT",
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      script={scripts}
      link={[]}
      meta={[
        {
          name: "google-site-verification",
          content: "QX7Xa_ljbrC-oT_7NhHj_7nEic9Yf6zVuigrJmb1Htc",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: metaImage.src,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ],
        )
        .concat(meta)}
    />
  );
};
Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
};
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  pathname: PropTypes.string,
};
export default Seo;
